import { inject, Injectable } from '@angular/core';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface IStoredMenuSectionData {
	isExpanded: boolean;
	menuItems?: IAuNavbarConfig[];
}

@Injectable()
export class NavbarMenuService {
	private readonly router = inject(Router);

	private readonly localStoreKey = 'navbarMenuState';
	private readonly localStoreUrlKey = 'urlBeforeSettings';

	constructor() {
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				// So the main idea behind this is to store the last url the user visited
				// before navigating to the settings page. Due to the fact that location.back()
				// will return user back 1 url stack before and if you had navigation between
				// different settings pages it will switch between them, and we don't want it
				if (this.isFirstNavigationToSettings(this.router.url, event.url)) {
					localStorage.setItem(this.localStoreUrlKey, this.router.url);
				}
			});
	}

	// Define your action methods here
	navigateToAddBuilding = (): void => {
		this.router.navigate(['all-assets/buildings/new-building']);
	};

	// Create a mapping between action names and methods
	actionMap: { [key: string]: () => void } = {
		navigateToAddBuilding: this.navigateToAddBuilding,
		// Add more action mappings as needed
	};

	// Method to execute actions based on action name
	executeAction(actionName: string): void {
		const actionMethod = this.actionMap[actionName];
		if (actionMethod) {
			actionMethod();
		} else {
			console.error(`Action '${actionName}' not found`);
		}
	}

	storeMenuState(menuConfig: IAuNavbarConfig[]): void {
		localStorage.setItem(this.localStoreKey, JSON.stringify(menuConfig));

		const views = menuConfig.find(item => item.title === 'Views');
		localStorage.setItem('navbarGlobalViewMenuState', JSON.stringify(views));
	}

	getStoredMenuState(): IAuNavbarConfig[] {
		const storedMenuState = localStorage.getItem(this.localStoreKey);
		if (storedMenuState) {
			return JSON.parse(storedMenuState);
		}
		return [];
	}

	getStoredSectionData(sectionKey: string): IStoredMenuSectionData {
		const rehydratedSectionData = localStorage.getItem(sectionKey);
		if (rehydratedSectionData) {
			return JSON.parse(rehydratedSectionData);
		}
		return { isExpanded: false };
	}

	isSettingsRouteUrl(url: string): boolean {
		// url string starts with '/' so we need the second element
		return url?.split('/')[1] === 'settings';
	}

	getPreviousNavigation(): string | null {
		return localStorage.getItem(this.localStoreUrlKey);
	}

	applyStoredMenuState(menuItems: IAuNavbarConfig[], storedMenu: IAuNavbarConfig[]) {
		if (!storedMenu || menuItems === undefined) {
			return;
		}

		menuItems.forEach(item => {
			const storedItemState = storedMenu?.find(
				storedItem => storedItem.title === item.title
			);

			if (storedItemState?.hasOwnProperty('expanded')) {
				item.expanded = storedItemState.expanded;
			}

			if (item.children?.length) {
				this.applyStoredMenuState(item.children, storedItemState?.children);
			}
		});
	}

	private isFirstNavigationToSettings(
		currentUrl: string,
		destinationUrl: string
	): boolean {
		return (
			!this.isSettingsRouteUrl(currentUrl) && this.isSettingsRouteUrl(destinationUrl)
		);
	}
}
