import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationLayoutComponent } from './application-layout.component';
import config from '@app/configs/au-main-config';
import { AutilityAuthGuard } from '@auth/guards/autility.auth.guard';
import { ErrorPageComponent } from '@app/core/error-page/error-page.component';
import { AuUtilsFunctions } from '@app/shared';
import { WithKeycloakAuthGuard } from '@auth/guards/keycloak.auth.guard';
import { provideState } from '@ngrx/store';
import {
	assetsReducer,
	PortfolioFeatureKey,
} from '@app/pages/settings/portfolio/state/area-assets.reducer';
import { provideEffects } from '@ngrx/effects';
import { AreaAssetsEffects } from '@app/pages/settings/portfolio/state/area-assets.effects';
import { ImportSessionResolver } from '@app/pages/files/resolvers/import-session-name.resolver';
import { viewsFeature } from '@app/pages/views/state/views.reducer';
import { ViewsEffects } from '@app/pages/views/state/views.effects';
import { CmmsFeatureKey, cmmsReducers } from '@app/pages/cmms/state/cmms.reducer';
import { CmmsEffects } from '@app/pages/cmms/state/cmms.effects';
import { RequestsEffects } from '@app/pages/cmms/components/request/state/requests.effects';
import { WorkOrderEffects } from '@app/pages/cmms/components/work-order/state/work-order.effects';
import { IncidentsEffects } from '@app/pages/cmms/components/incident/state/incidents.effects';
import { FilesEffects } from '@app/pages/cmms/components/files/state/files.effects';
import { AssetsEffects } from '@app/pages/cmms/components/assets/state/assets.effects';
import { VersionCheckGuard } from '@app/core/version-check/version-check.guard';
import { myBuildingBreadcrumbResolver } from '@app/pages/my-building/my-building-breadcrumb.resolver';

export const routes: Routes = [
	{
		path: '',
		component: ApplicationLayoutComponent,
		canActivate: [getCanActivateGuard()],
		canActivateChild: [VersionCheckGuard],
		providers: [provideState(viewsFeature), provideEffects(ViewsEffects)],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: config.auRoutes.dashboard.link,
			},
			{
				path: config.auRoutes.dashboard.link,
				loadChildren: () =>
					import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: config.auRoutes.cmms.link,
				loadChildren: () => import('../pages/cmms/cmms.module').then(m => m.CmmsModule),
			},
			{
				path: 'settings',
				providers: [
					provideState(PortfolioFeatureKey, assetsReducer),
					provideEffects(AreaAssetsEffects),
				],
				loadChildren: () => import('../pages/settings/settings.routes'),
				data: { breadcrumb: 'Settings' },
			},
			{
				path: config.auRoutes.digital_twin.link,
				loadChildren: () =>
					import('../pages/digital-twin/digital-twin.module').then(
						m => m.DigitalTwinModule
					),
			},
			//should be FilesPageComponent for first one, will be fixed when component is used
			// TODO @Fredrick: Move the inner router config inside Files Page component ref settings route
			{
				path: config.auRoutes.files.link,
				children: [
					{
						path: '',
						loadComponent: () =>
							import('../pages/files/files-page.component').then(
								m => m.FilesPageComponent
							),
					},
					{
						path: config.auRoutes.files.children.fileImport.link,
						data: { breadcrumb: 'Files Import' },
						children: [
							{
								path: '',
								loadComponent: () =>
									import('../pages/files/files-import/files-import.component').then(
										m => m.FilesImportComponent
									),
							},
							{
								path: ':id',
								loadComponent: () =>
									import('../pages/files/file-table/file-table.component').then(
										m => m.FileTableComponent
									),
								data: { breadcrumb: '' },
								resolve: {
									name: ImportSessionResolver,
								},
							},
						],
					},
				],
			},
			{
				path: config.auRoutes.errorPage.link,
				component: ErrorPageComponent,
			},
			{
				path: 'my-building/:buildingId',
				loadChildren: () => import('../pages/my-building/my-building.routes'),
				resolve: {
					breadcrumb: myBuildingBreadcrumbResolver,
				},
			},
			{
				path: 'views',
				providers: [
					provideState(viewsFeature),
					provideState(CmmsFeatureKey, cmmsReducers),
					provideEffects(
						ViewsEffects,
						CmmsEffects,
						RequestsEffects,
						WorkOrderEffects,
						IncidentsEffects,
						FilesEffects,
						AssetsEffects
					),
				],
				loadChildren: () => import('../pages/views/views.routes'),
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ApplicationLayoutRoutingModule {
	constructor() {}
}

function getCanActivateGuard() {
	if (AuUtilsFunctions.isAutilityAuth()) {
		return AutilityAuthGuard;
	} else if (AuUtilsFunctions.isKeycloakAuth()) {
		return WithKeycloakAuthGuard;
	}
}
