import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotsLoaderComponent } from '@shared/ui-components';
import { IAiChatMessage } from '@app/ai-chat/interfaces/ai-chat-message.interface';
import { MatIconModule } from '@angular/material/icon';
import { AiChatService } from '@app/ai-chat/services/ai-chat.service';
import { fileTypesConfig } from '@shared/ui-components/au-file-loader/au-file-loader.config';
import { UniqueSourceUrlsPipe } from '@app/ai-chat/pipes/unique-source-urls.pipe';

@Component({
	selector: 'ai-response-message',
	standalone: true,
	imports: [CommonModule, DotsLoaderComponent, MatIconModule, UniqueSourceUrlsPipe],
	templateUrl: './ai-response-message.component.html',
	styleUrls: ['./ai-response-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiResponseMessageComponent {
	private readonly aiChatService = inject(AiChatService);
	private readonly fileTypesConfig = fileTypesConfig;

	@Input() message: IAiChatMessage;

	isLoadingReply = this.aiChatService.isLoadingReply;

	getFileIcon(sourceUrl: string) {
		const extension = this.getFileExtensionFromUrl(sourceUrl);

		if (extension === 'unknown') {
			return 'document';
		}

		let fileType: string = 'file-unknown';

		Object.keys(this.fileTypesConfig).forEach(key => {
			if (this.fileFormatSupported(key, extension)) {
				fileType = key;
			}
		});

		return fileType === 'file-unknown' ? 'document' : this.fileTypesConfig[fileType].icon;
	}

	private fileFormatSupported(key: string, extension: string): boolean {
		return this.fileTypesConfig[key].supportedFormatsList.includes(extension);
	}

	private getFileExtensionFromUrl(rawUrl: string) {
		let url = rawUrl;
		if (url.includes('?')) {
			// Remove query parameters
			url = url.slice(0, url.indexOf('?'));
		}
		/**
		 * It starts from the position just after the last dot in the file name
		 * and includes all characters after the dot. The purpose of the
		 * bit manipulation (>>> 0) and adding 2 is to handle edge cases
		 * where there might not be a dot in the file name. This ensures that
		 * the resulting substring always starts from a non-negative position.
		 */
		const extension = url.slice(((url.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
		return extension.length ? extension : 'unknown';
	}
}
