<div class="icon-wrapper">
	<mat-icon svgIcon="robot" />
</div>
<div class="message-content">
	<h4>Ai-chat</h4>
	<ng-container *ngIf="!message.content; else showMessage">
		<dots-loader />
	</ng-container>
</div>

<ng-template #showMessage>
	<p
		class="chat-response body-2 grey"
		[class.with-sources]="!!message.sources?.length"
		[innerText]="message.content"
	></p>
	<div class="sources-wrapper">
		<div
			class="chat-response-source"
			*ngFor="let source of message.sources | uniqueSourceUrls"
		>
			<div class="file-type-icon">
				<mat-icon [svgIcon]="getFileIcon(source.url)" />
			</div>
			<a class="file-name" [href]="source.url" target="_blank">{{
				source.title || 'Untitled'
			}}</a>
		</div>
	</div>
</ng-template>
