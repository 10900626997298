import {
	Component,
	computed,
	DestroyRef,
	inject,
	OnInit,
	signal,
	WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { isNavSidebarCollapsed } from '@layout/state/layout.selectors';
import { NavSidebarCollapsed } from '@layout/state/layout.actions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Apollo } from 'apollo-angular';
import { BeVersion } from '@core/api/version.query';
import { filter, pluck } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { TitleComponent } from '@layout/title/title.component';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { JsonPipe, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { auNavbarConfig, settingsNavbarConfig } from '@app/configs/au-navbar.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavbarMenuService } from '@layout/au-nav-sidebar/navbar-menu.service';
import { NavbarMenuItemsComponent } from '@layout/au-nav-sidebar/navbar-menu-items/navbar-menu-items.component';
import { VersionCheckService } from '@app/core/version-check/version-check.service';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { selectCurrentBuildingId } from '@app/pages/views/state/views.reducer';
import { NavbarNavigationService } from './navbar-navigation-service';
import { AuUtilsFunctions } from '@app/shared';

@Component({
	selector: 'au-nav-sidebar',
	templateUrl: './au-nav-sidebar.component.html',
	styleUrls: ['./au-nav-sidebar.component.scss'],
	standalone: true,
	imports: [
		TitleComponent,
		MatListModule,
		MatRippleModule,
		NgIf,
		RouterLink,
		MatIconModule,
		RouterLinkActive,
		TitleCasePipe,
		NgForOf,
		JsonPipe,
		NavbarMenuItemsComponent,
	],
	providers: [NavbarMenuService],
})
export class AuNavSidebarComponent implements OnInit {
	private readonly store$: Store = inject(Store);
	private readonly apollo = inject(Apollo);
	private readonly breakpointObserver = inject(BreakpointObserver);
	private readonly router = inject(Router);
	private readonly destroyRef = inject(DestroyRef);
	private readonly menuService = inject(NavbarMenuService);
	private readonly versionCheckService = inject(VersionCheckService);
	private readonly FFService = inject(FeatureFlagService);
	private readonly navbarNavigationService = inject(NavbarNavigationService);

	isCollapsed: boolean;
	isMobile = true;
	versionFE: string = packageJson.version;
	versionBE: string;

	newVersionAvailable = this.versionCheckService.newVersionAvailable;
	currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
	isSettingsRoute: WritableSignal<boolean> = signal(
		this.menuService.isSettingsRouteUrl(this.router.url)
	);
	navigationConfig = computed(() => {
		if (this.isSettingsRoute()) {
			return settingsNavbarConfig(
				this.isRequesterCommentsEnabled,
				this.isIncidentImportEnabled
			);
		}

		let menuItems = auNavbarConfig();

		if (this.isMessagesEnabled) {
			menuItems = this.isEnabled(menuItems, 'messages');
		}
		if (this.isContactsEnabled) {
			menuItems = this.isEnabled(menuItems, 'contacts');
		}

		if (this.isViewsEnabled) {
			menuItems.push({
				...this.navbarNavigationService.getChildViews(),
			});
		}
		const storedMenuState = this.menuService.getStoredMenuState();
		this.menuService.applyStoredMenuState(menuItems, storedMenuState);
		return menuItems;
	});

	ngOnInit(): void {
		this.store$
			.select(isNavSidebarCollapsed)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(isCollapsed => (this.isCollapsed = isCollapsed));

		this.breakpointObserver
			.observe(['(max-width: 767px)'])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((state: BreakpointState) => {
				this.isMobile = state.matches;
			});

		this.apollo
			.use('federation')
			.query({
				query: BeVersion,
			})
			.pipe(pluck('data', 'version'), takeUntilDestroyed(this.destroyRef))
			.subscribe((value: string) => (this.versionBE = value));

		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter(event => event instanceof NavigationEnd)
			)
			.subscribe((event: NavigationEnd) => {
				this.isSettingsRoute.set(this.menuService.isSettingsRouteUrl(event.url));
				this.navbarNavigationService.currentActiveRoute(event.url.substring(1));
			});
	}

	closeSidebar(): void {
		if (this.isMobile) {
			this.store$.dispatch(NavSidebarCollapsed());
		}
	}

	navigateBack(): void {
		const previousUrl = this.menuService.getPreviousNavigation();
		if (previousUrl) {
			this.router.navigateByUrl(previousUrl);
		} else {
			this.router.navigate(['/']);
		}
	}

	get isRequesterCommentsEnabled(): boolean {
		return this.FFService.isFFEnabled('requester-comments');
	}

	get isViewsEnabled(): boolean {
		return this.FFService.isFFEnabled('building-views-v2');
	}

	get isMessagesEnabled(): boolean {
		return this.FFService.isFFEnabled('operational-messages');
	}

	get isContactsEnabled(): boolean {
		return this.FFService.isFFEnabled('contacts');
	}
	get isIncidentImportEnabled(): boolean {
		return this.FFService.isFFEnabled('incident-import');
	}

	isEnabled(menuItems: any, value: string) {
		const menuItemIndex = menuItems.findIndex(item => item.routerLink === 'cmms');
		menuItems[menuItemIndex]?.children.push({
			title: AuUtilsFunctions.capitalizeText(value),
			routerLink: value,
		});
		return menuItems;
	}
}
