import {
	computed,
	inject,
	Injectable,
	Signal,
	signal,
	WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	selectCurrentBuildingId,
	selectFolders,
	selectIsLoadingFolders,
} from '@app/pages/views/state/views.reducer';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class NavbarNavigationService {
	private readonly router = inject(Router);
	private readonly store$ = inject(Store);
	activeRoute: WritableSignal<string> = signal(this.router.url.substring(1));
	currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
	getFolders = this.store$.selectSignal(selectFolders);
	isLoadingFolders: Signal<boolean> = this.store$.selectSignal(selectIsLoadingFolders);
	folders = computed(() => (!this.isLoadingFolders() ? this.getFolders() : []));

	currentActiveRoute = route => {
		this.activeRoute.set(route);
	};

	getChildViews(buildingId = '') {
		let storedState = null;

		if (localStorage.getItem('navbarGlobalViewMenuState') !== 'undefined') {
			storedState = JSON.parse(localStorage.getItem('navbarGlobalViewMenuState'));
		}
		if (
			this.folders().length === 0 ||
			(!buildingId ? this.currentBuildingId() : buildingId !== this.currentBuildingId())
		) {
			return {
				title: 'Views',
				svgIcon: 'table-view',
				routerLink: 'views/folders-list',
			};
		}

		const global = buildingId ? [] : storedState?.children;

		const children = this.folders().map((view, index) => ({
			title: view.name,
			routerLink: `${view.pk}/view`,
			page: false,
			expanded: global?.[index]?.expanded,
			className: 'with-background',
			children: view.uiTableViewConfigs.map(childView => ({
				title: childView.name,
				routerLink: childView.pk,
			})),
		}));

		return {
			title: 'Views',
			svgIcon: 'table-view',
			routerLink: 'views/folders-list',
			className: 'folder',
			expanded: storedState?.expanded ? storedState?.expanded : true,
			children: children,
		};
	}
}
