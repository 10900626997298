import {
	ChangeDetectionStrategy,
	Component,
	computed,
	EventEmitter,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';
import { MatIconModule } from '@angular/material/icon';
import { NavbarMenuService } from '@layout/au-nav-sidebar/navbar-menu.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NavbarNavigationService } from '../../navbar-navigation-service';

@Component({
	selector: 'menu-item-content',
	standalone: true,
	imports: [CommonModule, MatIconModule, RouterLink, RouterLinkActive, MatMenuModule],
	templateUrl: './menu-item-content.component.html',
	styleUrls: ['./menu-item-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemContentComponent {
	private readonly navbarMenuService = inject(NavbarMenuService);
	readonly navbarNavigationService = inject(NavbarNavigationService);

	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	@Input({ required: true }) menuItem: IAuNavbarConfig;
	@Input({ required: true }) level: number;
	@Input() parentRouteLink: string | null = null;
	@Input() alwaysExpanded: boolean;

	@Output() expandedChange = new EventEmitter<void>();

	parentRouteLinkForChild: string | null = null;

	currentLink = computed(() => {
		return this.menuItem.page !== false ? this.parentRouteLinkForChild : null;
	});
	activeRoute = computed(() => {
		if (this.parentRouteLinkForChild?.includes('digital-twin')) {
			return this.navbarNavigationService
				.activeRoute()
				.startsWith(this.parentRouteLinkForChild);
		}
		return this.parentRouteLinkForChild === this.navbarNavigationService.activeRoute();
	});

	ngOnInit() {
		this.getRouterLink();
	}

	onClickAction(event: MouseEvent, actionName: string): void {
		event.stopPropagation();
		this.navbarMenuService.executeAction(actionName);
	}

	toggleExpanded(item: IAuNavbarConfig): void {
		if (this.alwaysExpanded) {
			return;
		}

		if (item.children && item.children.length > 0) {
			item.expanded = !item.expanded;
		}
		this.expandedChange.emit();
	}

	onMenuItemClicked($event: MouseEvent) {
		$event.stopPropagation();
		if (this.menuItem.onClickAction) {
			this.menuItem.onClickAction();
		} else {
			this.toggleExpanded(this.menuItem);
		}
	}

	isMenuItemVisible(menuItem: IAuNavbarConfig): boolean {
		return !!menuItem.title;
	}

	getRouterLink(): string | null {
		if (!this.parentRouteLink) {
			this.parentRouteLinkForChild = this.menuItem.routerLink;

			return this.parentRouteLinkForChild;
		}

		this.parentRouteLinkForChild = [this.parentRouteLink, this.menuItem.routerLink]
			.filter(Boolean)
			.join('/');

		return this.parentRouteLinkForChild;
	}

	openMenu($event: MouseEvent): void {
		$event.stopPropagation();
		this.trigger.openMenu();
	}
}
