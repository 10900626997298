<div class="navigation-overlay" [class.collapsed]="isCollapsed">
	<div class="navbar-content">
		<app-title *ngIf="!isSettingsRoute(); else settingsTitle" />
		<navbar-menu-items
			[menuItems]="navigationConfig()"
			[isSettingsRoute]="isSettingsRoute()"
		/>
	</div>

	<div class="version">
		<div>
			v{{ versionFE }}
			@if (newVersionAvailable()) {
				<span>{{ '> ' }}{{ newVersionAvailable() }}</span>
			}
		</div>
		<div>{{ versionBE }}</div>
	</div>
</div>

<ng-template #settingsTitle>
	<div class="au-settings-title-container" (click)="navigateBack()">
		<div class="back-button">
			<mat-icon svgIcon="nav_arrow_backward" />
		</div>
		<p class="settings-title">Settings</p>
	</div>
</ng-template>
