{
  "name": "frontend",
  "version": "1.25.344",
  "scripts": {
    "ng": "ng",
    "start": "yarn run create-version && ng serve",
    "build": "yarn run create-version && node --max_old_space_size=6122 ../node_modules/@angular/cli/bin/ng build --configuration=production",
    "build-dev": "yarn run create-version && node --max_old_space_size=6122 ../node_modules/@angular/cli/bin/ng build --configuration=stage",
    "build-qa": "yarn run create-version && node --max_old_space_size=6122 ../node_modules/@angular/cli/bin/ng build --configuration=qa && yarn run sentry:sourcemaps",
    "create-version": "node ./create-version-file.js",
    "test": "ng test",
    "test:ci": "node --max_old_space_size=6122 ../node_modules/@angular/cli/bin/ng test --karma-config=karma-ci.conf.js",
    "lint": "ng lint",
    "increment": "git pull && yarn version -i patch && git commit -a -m 'Raise patch version;' && git push",
    "publish": "git tag -a \"v$(node -p \"require('./package.json').version\")\" -m 'Create tag version;' && git push --tags",
    "publish-master-to-prod": "git checkout master && yarn run increment && yarn run publish",
    "publish-qa-to-qa": "git checkout qa && yarn run increment",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org autility-edc11cf12 --project javascript-angular ./dist && sentry-cli sourcemaps upload --org autility-edc11cf12 --project javascript-angular ./dist"
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.10.39",
    "@amcharts/amcharts5": "^5.10.5",
    "@angular/animations": "^18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@apollo/client": "^3.11.8",
    "@autility/digital-twin-service": "workspace:library",
    "@intercom/messenger-js-sdk": "^0.0.14",
    "@mapbox/mapbox-gl-geocoder": "^5.0.3",
    "@netcreaties/ngx-smart-banner": "^2.0.0-1",
    "@ngrx/component": "^18.0.2",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/router-store": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@sentry/angular": "~8.12.0",
    "@sentry/cli": "^2.36.3",
    "@supy-io/ngx-intercom": "^14.2.12",
    "@web-comp/core": "^1.0.2",
    "@web-comp/json-viewer": "^1.0.2",
    "apollo-angular": "^7.2.0",
    "dexie": "^3.2.7",
    "extract-files": "^13.0.0",
    "graphql": "^16.9.0",
    "graphql-tag": "^2.12.6",
    "hammerjs": "^2.0.8",
    "keycloak-angular": "16.0.1",
    "keycloak-js": "21.1.2",
    "lz-string": "^1.5.0",
    "mapbox-gl": "^2.15.0",
    "ngrx-store-freeze": "^0.2.4",
    "ngx-doc-viewer": "^15.0.1",
    "ngx-image-cropper": "^6.3.4",
    "ngx-mapbox-gl": "^11.0.1",
    "ngx-mask": "^16.4.2",
    "ngx-pendo": "^1.14.3",
    "pdfjs-dist": "^3.8.162",
    "rxjs": "^7.8.1",
    "socket.io": "^4.8.0",
    "socket.io-client": "^2.5.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-devkit/core": "^18.2.6",
    "@angular-devkit/schematics": "^18.2.6",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/language-service": "^18.2.6",
    "@html-eslint/eslint-plugin": "^0.27.0",
    "@html-eslint/parser": "^0.27.0",
    "@ngrx/schematics": "^18.0.2",
    "@schematics/angular": "^18.2.6",
    "@types/jasmine": "^5.1.4",
    "@types/lz-string": "^1.5.0",
    "@types/mapbox-gl": "^2.7.21",
    "@types/mapbox__mapbox-gl-geocoder": "^4.7.7",
    "@types/node": "^20.16.9",
    "@types/node-fetch": "^2.6.11",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "@typescript-eslint/utils": "^7.18.0",
    "cpy": "^11.1.0",
    "cpy-cli": "^5.0.0",
    "esbuild": "^0.17.19",
    "eslint": "^8.57.1",
    "jasmine-core": "^5.3.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "patch-package": "^8.0.0",
    "prettier": "^3.3.3",
    "sass": "^1.79.3",
    "ts-node": "^10.9.2",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "~5.4.5"
  }
}
